<template>
    <defaultSec :title="'SOP推送列表'" class="route-customer-wrap">
        <el-form :inline="true" class="demo-form-inline select-wrap" size="mini">
            <el-form-item label="日期">
                <el-date-picker
                        v-model="formParam.createDate"
                        type="date"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="群组/备注/昵称">
                <el-input type="text" v-model="formParam.searchText" placeholder="输入群组/备注/昵称查询 "></el-input>
            </el-form-item>
            <el-form-item label="选择企微" v-if="formParam.all==1">
                <el-select clearable :popper-append-to-body="false" size="mini" value-key="id"
                           v-model="formParam.qwUserId">
                    <el-option v-for="user in userList" :label="user.name" :value="user.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="执行状态">
                <el-select v-model="formParam.status" placeholder="请选择">
                    <el-option label="全部" :value="-6"></el-option>
                    <el-option label="已过滤" :value="-2"></el-option>
                    <el-option label="被禁用" :value="-3"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="素材组">
                <el-select clearable :popper-append-to-body="false" size="mini" value-key="id"
                           v-model="formParam.groupId">
                    <el-option v-for="user in groupList" :label="user.groupName" :value="user.id"></el-option>
                </el-select>
            </el-form-item>
            <!--<el-form-item label="推送类型">-->
                <!--<el-select v-model="formParam.pushType" placeholder="请选择">-->
                    <!--<el-option label="手动推送" :value="1"></el-option>-->
                    <!--<el-option label="自动推送" :value="2"></el-option>-->
                <!--</el-select>-->
            <!--</el-form-item>-->
            <el-form-item>
                <el-button type="primary" @click="initList">查询</el-button>
            </el-form-item>
        </el-form>
        <tablePagination :total="total" :pageSize="formParam.pageSize" @handlePage="handlePage">
            <el-table :data="tableList" border size="mini">
                <el-table-column type="index" label="序号" width="70"></el-table-column>
                <el-table-column width="210" prop="routeName" label="群组信息">
                    <template slot-scope="scope">
                        <div style="font-size: 14px;font-weight: 600">{{scope.row.sceneName}}</div>
                        <div style="font-size: 10px;font-style: italic">
                            <i style="color: #2dacd1" class="el-icon-s-order"></i>备注:{{scope.row.sceneRemark}}

                        </div>
                        <div style="font-size: 10px;font-style: italic">
                            <i style="color: #55a532" class="el-icon-s-promotion"></i>来自旅程:{{scope.row.routeName}}

                        </div>
                        <!--                      <div style="font-size: 10px;font-style: italic">-->
                        <!--                        <i style="color: #55a532" class="el-icon-user-solid"></i>所属企微:{{scope.row.qyWeixinUserName||scope.row.qyWeixinUserid}}-->
                        <!--                      </div>-->
                        <div style="font-size: 10px;font-style: italic" v-if="scope.row.remark">
                            <i style="color: #55a532" class="el-icon-paperclip"></i>{{scope.row.remark}}

                        </div>

                    </template>
                </el-table-column>
                <el-table-column prop="qyWeixinUserid" label="所属企微" width="120">
                    <template slot-scope="scope">
                        {{scope.row.qyWeixinUserName || scope.row.qyWeixinUserid}}
                    </template>
                </el-table-column>
                <el-table-column width="180" prop="nickName" label="用户信息 (可进入用户详情)">
                    <template slot-scope="scope">
                        <div @click="toDetail(scope.row.customerId)">
                            <p class="user-info" style="text-align: center">
                                <el-avatar icon="el-icon-user-solid" :size="30" :src="scope.row.avatar"></el-avatar>
                            </p>
                            <el-link class="user-info"><span
                                    style="color: #55a532">● </span>昵称：{{scope.row.nickName || '微信用户'}}
                            </el-link>
                            <el-link class="user-info"><span style="color: #55a532">● </span>手机号：{{scope.row.phone}}
                            </el-link>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="pushText" label="推送文案" width="320">
                    <template slot-scope="scope">
                        <div v-if="scope.row.pushText" style="position: relative;padding-top: 25px;">
                            <span style="font-weight: 600;position: absolute;top: 0;left: 0">文案编号:{{scope.row.materialCode}}&nbsp;&nbsp;前置文案:{{scope.row.materialParentCode || '无'}}</span>
                            <el-button @click="copyText(scope.row)" style="position: absolute;top: 0;right: 0"
                                       size="mini" type="text" icon="el-icon-document-copy">复制
                            </el-button>
                            <p class="copy-text" slot="reference" v-html="scope.row.pushText"></p>
                        </div>
                    </template>
                </el-table-column>
                <!--<el-table-column prop="keyword" label="非标文案" width="300">-->
                    <!--<template slot-scope="scope">-->
                        <!--<div v-if="scope.row.keyword" style="position: relative;">-->
                            <!--<p class="copy-text" slot="reference" v-html="scope.row.keyword"></p>-->
                        <!--</div>-->
                    <!--</template>-->
                <!--</el-table-column>-->
                <el-table-column prop="kefuUseStatus" label="状态" width="120">
                    <template slot-scope="scope">
                        <el-tag size="mini" type="danger" v-if="scope.row.kefuUseStatus==0">待处理</el-tag>
                        <el-tag size="mini" type="success" v-if="scope.row.kefuUseStatus==1">已处理</el-tag>
                        <el-tag size="mini" type="warning" v-if="scope.row.kefuUseStatus==2">已发送</el-tag>
                        <el-tag size="mini" type="warning" v-if="scope.row.kefuUseStatus==3">已回复</el-tag>
                        <el-tag size="mini" type="warning" v-if="scope.row.kefuUseStatus==-2">被过滤</el-tag>
                        <el-tag size="mini" type="info" v-if="scope.row.kefuUseStatus==-3">被禁用</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="reason" label="原因">
                </el-table-column>
                <el-table-column prop="createTime" label="创建时间"  fixed="right"></el-table-column>

            </el-table>
        </tablePagination>
        <el-drawer :visible.sync="showExampleDialog" title="成功案例" size="50%">
            <div style="padding: 0 20px" id="loading-wrap">
                <el-card class="box-card" v-for="item in exampleList" style="margin-bottom: 20px">
                    <div slot="header" class="clearfix" style="display: flex;align-items: center">
                        <el-avatar style="margin-right: 10px" size="medium" :src="item.avatar"></el-avatar>
                        <div>
                            <span style="display: block;">{{ item.nick_name }}</span>
                            <span style="display: block;font-size: 10px;color: #999999">上次聊天：{{item.end_time}}</span>
                        </div>
                    </div>
                    <div v-for="item1 in item.chats" class="chat-item"
                         :class="item1.speakType==2?'':'chat-item-reverse'">
                        <div class="chat-img">
                            <el-avatar size="small"
                                       :src="item1.speakType==2?item.avatar:'http://lechunbucket.oss-cn-beijing.aliyuncs.com/kboneMiniResource/public/lechun-logo.jpg'"></el-avatar>
                        </div>
                        <div class="chat-miniprogram" v-if="item1.msgType=='weapp'">
                            <p style="display: flex;align-items: center;font-size: 10px">
                                <el-avatar style="margin-right: 6px" size="small"
                                           src="http://lechunbucket.oss-cn-beijing.aliyuncs.com/kboneMiniResource/public/lechun-logo.jpg"></el-avatar>
                                乐纯
                            </p>
                            <p>{{item1.weappTitle}}</p>
                            <img style="width: 200px;height: 150px"
                                 src="http://lechunbucket.oss-cn-beijing.aliyuncs.com/kboneMiniResource/public/lechun-logo.jpg">
                            <p style="border-top: 1px solid #dddddd;padding-top: 6px;font-size: 10px">小程序</p>
                        </div>
                        <div class="chat-content" v-else-if="item1.msgType=='text'" v-html="item1.textContent"></div>
                        <div class="chat-content" v-else>[ {{item1.msgType}} ]</div>
                    </div>
                </el-card>
            </div>
            <el-empty v-if="exampleList.length<=0">
                <template slot="description">
                    <i v-if="exLoading" style="font-size: 40px" class="el-icon-loading"></i>
                    <span v-else>暂无</span>
                </template>
            </el-empty>


        </el-drawer>
    </defaultSec>
</template>

<script>
    import defaultSec from '@c/defaultSection'
    import tablePagination from '@c/tablePagination'
    import {wxQw} from "@/api/wxQwConfig";
    let wx = window.wx
    let qwPlatform = navigator.userAgent.indexOf('wxwork') > -1 ? true : false
    export default {
        data() {
            return {
                total: 0,
                tableList: [],
                routeList: [],
                formParam: {
                    pageSize: 20,
                    currentPage: 1,
                    createDate: new Date().Format("yyyy-MM-dd"),
                    status: -6,
                    all: this.$route.query.all,
                    qwUserId: '',
                    groupId: '',
                    pushType: ''
                },
                configInitStatus: 1,
                userList: [],
                groupList: [],
                exampleList: [],
                showExampleDialog: false,
                exLoading: true,
            }
        },
        components: {
            defaultSec, tablePagination
        },
        mounted(){
            //企微打开:config
            this.initRouteOption();
            this.initList();
            this.initUserList();
            this.initgroupList();
        },
        methods: {
            initList() {
                var userAgent = window.navigator.userAgent;
                this.formParam.all = userAgent.indexOf("wxwork") > 0 ? 0 : 1;

                this.$apiGet('lechun-cms/scrmRoute/getQwServiceList', this.formParam).then(res => {
                    this.total = res.total;
                    this.tableList = res.list;
                })
            },
            //企微客服列表
            initUserList(){
                this.$apiGet('lechun-cms/scrmCalendar/getQwUser', {}).then(res => {
                    console.log(res)
                    this.userList = res
                })
            },
            //素材分组列表
            initgroupList(){
                this.$apiGet('lechun-cms/sceneRouteGroup/getGroupList', {
                    currentPage: 1,
                    pageSize: 10000,
                    sceneRoute: 3
                }).then(res => {
                    console.log(res)
                    this.groupList = res.list
                })
            },
            initRouteOption(){
                this.$apiGet('lechun-cms/scrmRoute/getServiceOption', {}).then(res => {
                    this.routeList = res;
                })
            },
            handlePage(page){
                this.formParam.currentPage = page;
                this.initList()
            },
            copyText(row){
                var input = document.createElement("textarea"); // 创建input对象
                input.value = row.pushText; // 设置复制内容
                document.body.appendChild(input); // 添加临时实例
                input.select(); // 选择实例内容
                document.execCommand("Copy"); // 执行复制
                document.body.removeChild(input); // 删除临时实例
                this.$message({
                    type: 'success',
                    message: '已复制到剪贴板'
                });
            },
            copyPhone(row){
                if (!qwPlatform) {
                    this.$message({
                        message: '浏览器不支持打开企微对话,请在企业微信打开',
                        type: 'warning'
                    });
                    return;
                }
                this.copyText(row);
                this.$apiGet('lechun-cms/scrmRoute/setProcessStatus', {'id': row.id}).then(res => {
                    console.log("res---", res)
                    row.kefuUseStatus = 1;
                    this.onChat(row);
                    this.initList()
                })
            },
            onChat(row){
                if (!qwPlatform) {
                    this.$message({
                        message: '浏览器不支持打开企微对话,请在企业微信打开',
                        type: 'warning'
                    });
                    return;
                }
                wx.openEnterpriseChat({
                    // 注意：userIds和externalUserIds至少选填一个。内部群最多2000人；外部群最多500人；如果有微信联系人，最多40人
                    externalUserIds: row.externalUserid, // 参与会话的外部联系人列表，格式为userId1;userId2;…，用分号隔开。
                    groupName: '',  // 会话名称。单聊时该参数传入空字符串""即可。
                    chatId: "", // 若要打开已有会话，需指定此参数。如果是新建会话，chatId必须为空串
                    success: function (res) {
                        console.log(res)
                    },
                    fail: function (res) {
                        console.log(res);
                        if (res.errMsg.indexOf('function not exist') > -1) {
                            alert('版本过低请升级')
                        }
                    }
                });
            },
            toDetail(id){
                let url = window.location.origin + '/userDetail/' + id
                window.open(url, '_blank')
            },
            getConfigInfo()    {
                let appSignature; // 通过接口，获取agentConfig的参数
                const urlNow = encodeURIComponent(window.location.href);
                console.log('当前授权URL：', urlNow);
                const noncestr = Math.floor(Math.random() * 100000000000000);
                let body = {
                    url: urlNow,
                    timestamp: 1000,
                    nonceStr: noncestr,
                    type: "agent_config"
                };
                let that = this;
                this.$apiGet('lechun-cms/distributor/getQwConfig', body).then(res => {
                    appSignature = res;
                    console.log(appSignature);
                    console.log('wx.agentConfig:BEGIN');
                    wx.agentConfig({
                        corpid: appSignature.corpid, // 必填，企业微信的corpid，必须与当前登录的企业一致
                        agentid: appSignature.agentid, // 必填，企业微信的应用id （e.g. 1000247）生产环境
                        timestamp: appSignature.timestamp, // 必填，生成签名的时间戳
                        nonceStr: appSignature.nonceStr, // 必填，生成签名的随机串
                        signature: appSignature.signature, // 必填，签名，见附录-JS-SDK使用权限签名算法
                        jsApiList: [
                            'openUserProfile',
                            'previewFile',
                            'getCurExternalContact',
                            'openEnterpriseChat'
                        ], // 必填
                        debug: 1,
                        success(res) {
                            console.log(res)
                        },
                        fail(res) {
                            console.log('err', res);
                            that.configInitStatus = 0;
                            if (res.errMsg.indexOf('function not exist') > -1) {
                                alert('版本过低请升级');
                            }
                        }
                    });
                })
            },
            /*展示成功案例*/
            showExample(e){
                this.exampleList = []
                this.exLoading = true
                this.showExampleDialog = true
                this.$apiGet('lechun-bi/generateScene/getSuccessCase', {materialId: e.materialId}).then(res => {
                    console.log(res)
                    this.exLoading = false
                    this.exampleList = res

                })
            }
        }
    }
</script>

<style lang="scss" rel="stylesheet/scss">
    .route-customer-wrap {
        .user-info {
            margin: 0;
            cursor: pointer;
            font-size: 12px;
            font-weight: normal;
        }
        .copy-text {
            white-space: pre-line;
            margin: 0;
            line-height: 18px;
            background: #f2f2f2;
            border: 1px solid #e8e8e8;
            border-radius: 5px;
            padding: 6px;
            max-height: 130px;
            overflow-y: scroll;
          scrollbar-width: none;
        }
        .el-card__header {
            border-bottom: 1px solid #d7d7d7 !important;
        }
        .chat-item {
            display: flex;
            margin-bottom: 20px;
            font-size: 12px;
        }

        .chat-item-reverse {
            flex-direction: row-reverse;
        }
        .chat-img {
            width: 28px;
            display: block;
            margin-right: 10px;
        }
        .chat-img img {
            width: 28px !important;
        }
        .chat-item-reverse .chat-img {
            margin-left: 10px;
            margin-right: 0;
        }
        .chat-content {
            background: #f5f5f5;
            padding: 10px;
            border-radius: 5px;
            display: inline-block;
            white-space: pre-wrap;
            max-width: 80%;
        }
        .chat-item-reverse .chat-content {
            background: #a6e860;
        }
        .chat-miniprogram {
            border: 1px solid #dddddd;
            border-radius: 5px;
            padding: 10px;
            width: 200px;
        }
    }

</style>
